.content-area-table {
  background: var(--secondary-color);
  border-radius: 5px;
  box-shadow: var(--light-shadow1);
  padding: 12px 16px;

  @media (max-width: 768px) {
    padding: 12px;
  }

  .data-table-title {
    font-size: 18px;
    color: var(--xl-text-color);
    margin-bottom: 12px;
  }

  .data-table-diagram {
    border-radius: 6px;
    border: 1px solid var(--tbl-border-color) !important;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--scroll-track-bg-color);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100vh !important;
      background-color: #e9e9e9;
      outline: 1px solid rgba(0, 0, 0, 0.02);
      outline-offset: -1px;
    }

    table {
      min-width: 900px;
      width: 100%;
      border-collapse: collapse;
      color: var(--text-color-inverted);

      td,
      th {
        padding: 14px 14px;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 15px;
          padding: 12px 10px;
        }
      }

      thead {
        text-align: start;
        font-size: 17px;
        background-color: var(--thead-bg-color);

        th {
          &:last-child {
            text-align: center;
          }
        }
      }

      .dt-cell-action {
        text-align: center;
      }

      .dt-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 6px;

        .dt-status-dot {
          width: 8px;
          height: 8px;
          border-radius: 100%;

          &.dot-pending {
            background-color: var(--orange-color);
          }
          &.dot-canceled {
            background-color: var(--red-color);
          }
          &.dot-confirmed {
            background-color: var(--green-color);
          }
          &.dot-Scheduled {
            background-color: var(--red-color);
          }
          &.dot-Arrived {
            background-color: var(--green-color);
          }
        }

        .dt-status-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .action-dropdown-btn {
    color: var(--text-color-inverted);
    position: relative;
  }

  .action-dropdown-menu {
    position: absolute;
    top: 100%;
    inset-inline-end: 50%;
    transform: translateX(50%);
    height: auto;
    min-width: 80px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 1px 4px 0px;
    background-color: var(--secondary-color);
    padding: 6px 10px;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid var(--border-color-inverted);
    text-align: start;
  }

  .dropdown-menu-item {
    margin: 8px 0;
  }

  .dropdown-menu-link {
    color: var(--text-color-inverted);
    font-weight: 600;
    transition: var(--default-transition);

    &:hover {
      opacity: 0.9;
    }
  }
}
.search-bar {
  margin: 20px 0;
  display: flex;
  justify-content: center;

  input {
    width: 300px;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;

  li {
    margin: 0 5px;

    a {
      text-decoration: none;
      color: #333;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;

      &:hover,
      &.active {
        background-color: #f0f0f0;
      }
    }

    &.disabled a {
      color: #999;
      cursor: not-allowed;
    }
  }
}

.input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.draggable-input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.ColumnNameInput.TextArea {
  padding-inline-start: 30px;
}

.DraggableColumnNameInput.TextArea {
  padding-inline-start: 40px;
  width: 95%;
}

.search-icon {
  position: absolute;
  inset-inline-start: 10px;
  cursor: pointer;
}
.search-icon:hover {
  color: #007bff;
}

.sidebar-search-icon {
  position: absolute;
  inset-inline-start: 10px;
  cursor: pointer;
  color: black;
}

.dark-theme .sidebar-search-icon {
  color: white;
}

.sidebar-input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* search all */

.searchContainer {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  inset-inline-end: 20%;
  border-end-start-radius: 15px;
  border-end-end-radius: 15px;
  background-color: #1e2836;
  color: white;
  z-index: 10;
  top: 0;
  transition: top 0.24s ease;
}

.search-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  box-shadow: 0 1px 2px -3px rgba(0, 0, 0, 0.1);
}

.searchInput {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  padding: 0 3px;
  padding-inline-start: 5px;
  background-color: #f1f1f1;
  color: black;
}

.dark-theme .searchInput {
  background-color: #383838;
  color: white;
}

.search-line {
  position: absolute;
  bottom: -6px;
  cursor: grab;
}

.search-instructions {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 2px;
  margin-top: 0;
}

.download-afj {
  justify-content: center;
  margin-top: 20px;
}

.hide {
  top: -170px;
}

.media-radio-search {
  margin-top: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* CSS for the Download Button */
.search-download-button {
  background-color: #4caf50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 10px 10px; /* Some padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from links */
  font-size: 14px; /* Increase font size */
  margin: 12px 2px; /* Add some margin around the button */
  cursor: pointer; /* Change mouse cursor on hover */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background color change on hover */
}

.search-download-button:hover {
  background-color: #45a049; /* Darker shade of green on hover */
}

.afj-search-container {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.afj-search-container::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px; /* Adjust the height of the border to make it thinner */
  background-color: #2c3644; /* Adjust the color of the border as needed */
  position: absolute;
  bottom: -10px; /* Adjust this value to match the margin-bottom */
  inset-inline-start: 0;
}

.afj-search-icon {
  font-size: 24px;
  color: white;
  cursor: pointer;
  margin-inline-end: 8px;
  margin-inline-start: 10px;
  transition: color 0.2s ease-in-out;
}

.afj-search-icon:hover {
  color: #555;
}

.afj-search-input {
  width: 0;
  border: none;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: width 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
}

.afj-search-container.expanded .afj-search-input {
  width: 60%;
  padding: 8px 12px;
  opacity: 1;
  visibility: visible;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: width 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
}

.afj-search-input:focus {
  border-color: #007bff;
}

* {
  padding: 0;
}

h2 {
  font-size: 20px;
  padding-inline-start: 16px;
}

h3 {
  font-size: 16px;
}

.ButtonLayout {
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  opacity: 0.8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.popupheader {
  font-size: 16px;
  font-weight: 600;
}

.TextArea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  outline: 1px solid #dadada;
  border: none;
  box-sizing: border-box;
  transition: outline 0.2s linear;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.TextArea:focus {
  outline: 1px solid #9e9e9e;
  border: none;
  box-shadow: none;
}

.chips-container {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.left-side-search {
  display: flex;
  align-items: center;
}

.right-side-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.column-header-icon {
  background-color: #1e2836;
  color: #ffffff;
  padding: 4px;
  border-radius: 3px;
  cursor: pointer;
}

.material-icons.column-header-icon {
  font-size: 25px;
}

.material-icons.chip-icon {
  font-size: 30px;
}

.column-header-filter-title {
  color: #1e2836;
  padding-inline-end: 8px;

  margin: 0;
}
.vertical-line {
  border-inline-start: 1px solid #ccc;
  margin: 0 1px;
}

.chip-icon {
  background-color: #1e2836;
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.chooseChannel {
  width: 98%;
}

.ReadOnly {
  background-color: rgb(185, 185, 185);
  border-color: rgb(160, 160, 160);
  color: gray;
}

.IconContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.IconSelect {
  flex: 1;
  max-width: 150px;
  border-radius: 5px;
}

.CheckboxLabel {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PostsType {
  padding-top: 8px;
}

.PostsTypeCheck {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
}

.Checkbox {
  margin-inline-end: 10px;
}

.ColumnContainer {
  display: flex;
  width: 100%;
  box-sizing: content-box;
  flex-direction: column;
  padding: 0px;
  border-radius: 8px;
  overflow: hidden;
}

.SettingsSection {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(50px, 100px) 1fr;
  border-radius: 5px;
}

.SettingsContainer {
  width: 680px;
  max-width: 100%;
  padding: 13px;
}

.flexy {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.flexy:first-child {
  margin-top: 0;
  padding-top: 0;
}

.flexy:last-child {
  border-bottom: none;
}

.SettingsCategories {
  border-inline-end: 1px solid #ccc;
  width: fit-content;
}

.SettingsContent {
  width: auto;
  margin-top: 10px;
  padding-inline-start: 40px;
  margin-inline-start: 20px;
}

.SettingsContentOption {
  margin-inline-end: 25px;
  display: flex;
  align-items: flex-end;
}

.SettingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SettingSubText {
  size: 16px;
}

.CategoryList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-decoration: none;
}

.CategoryListItem {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #545258;
  transition: color 0.3s ease;
  cursor: pointer;
  position: relative;
}

.dark-theme .CategoryListItem {
  color: #bcb8cb;
}

.light-theme .CategoryListItem.active {
  color: #000;
}

.CategoryListItem::after {
  content: "";
  position: absolute;
  inset-inline-start: 50%;
  /* Center the pseudo-element */
  bottom: 0;
  width: 0;
  /* start with no underline */
  height: 2px;
  /* thickness of the underline */
  background: linear-gradient(90deg, #6d6a75, #6d6a75);
  /* gradient color */
  transition: width 0.3s ease, transform 0.3s ease;
  /* animate the width and horizontal position */
  transform: translateX(-50%);
  /* Shift it back by half its own width to center it */
}

.CategoryListItem.active,
.CategoryListItem:hover {
  color: #000;
  /* Active and hover color */
}

.dark-theme .CategoryListItem.active,
.dark-theme .CategoryListItem:hover {
  color: #fff;
}

.dark-theme .SettingsLogoutButton {
  background: transparent;
}

.dark-theme.material-icons {
  color: white;
}

.light-theme .SettingsLogoutButton {
  background-color: transparent;
  margin-inline-start: 3px;
}

.dark-theme .SettingsLogoutButton {
  color: white;
}

.light-theme.material-icons {
  background-color: transparent;
}

.side {
  padding-inline-end: 10px;
  background-color: transparent;
}

.SettingsLogoutButton {
  background-color: transparent;
  border: none;
}

.SettingsLogoutButton:hover {
  color: red;
}

.Logout {
  border: none;
}

.export.material-icons {
  margin-bottom: 3px;
  margin-inline-end: 5px;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: #180202;
}

.SettingsLogoutButton {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  border: transparent;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
}

.Button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-inline-start: 10px;
}

.DoneButton {
  color: white;
  font-weight: bold;
  padding: 12px 36px;
  margin: 0 16px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.DoneButton:hover {
  transform: scale(1.1);
}

.CancelButton {
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 12px 32px;
  cursor: pointer;
}

.CloseButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dark-theme .CloseButton {
  color: white;
}

.dark-theme .CloseButton .close {
  color: white;
}

.bundleLink {
  color: #000;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  font: inherit;
  cursor: pointer;
}

.light-theme .bundleLink {
  color: black;
  background-color: transparent;
}

.light-theme .bundleLink:hover {
  color: #121472;
}

.dark-theme .bundleLink {
  color: white;
  background-color: transparent;
}

.dark-theme .bundleLink {
  color: white;
  background-color: transparent;
}

.dark-theme .bundleLink:hover {
  color: #bcb8cb;
  background-color: transparent;
}

.ChannelsContainer {
  border: 1px solid #ccc;
  overflow-y: auto;
  overflow-x: hidden;
  height: 60vh;
  box-sizing: border-box;
  margin-top: 8px;
}

.ChannelProfileandName {
  display: flex;
  align-items: center;
}

.ChannelName {
  padding: 4px 6px;
}

.ChannelElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 0.4px solid #ccc;
}

.LogoutButton {
  background-color: red;
  color: white;
  padding: 14px 30px;
  transition: 0.3s;
}

.LogoutButton:hover {
  opacity: 1;
  background-color: red;
  transform: scale(1.1);
}

.LogoutSpacing {
  margin-top: 36px;
}

.CloseIcon {
  font-size: 24px;
  color: black;
}

.ChannelIcon {
  width: 40px;
  border-radius: 50px;
}

.custom-single-value {
  display: flex;
  align-items: center;
}

.custom-option {
  display: flex;
  align-items: center;
}

.IconContainer h3 {
  margin-bottom: 5px;
}

.SectionDivider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px;
}

.ColumnDivider {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 1px;
}

.ArrowRightButton {
  background-color: transparent;
  border: none;
}

.Channel {
  cursor: pointer;
  transition: background-color 0.3s;
}

.loading-message {
  display: flex;
  align-items: center;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: #000;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
  margin-inline-end: 8px;
}

.channel_username {
  color: rgb(66, 146, 195);
  font-size: 13px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.channel_name {
  font-size: 15px;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.ChooseChannelDiv {
  position: relative;
  /* Ensure the container has relative positioning */
  width: 100%;
}

.chooseChannelContainer {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.chooseChannel {
  margin: 0;
}

.DropdownMenu {
  position: absolute;
  top: 100%;
  inset-inline-start: 0.8%;
  inset-inline-end: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  width: 98%;
}

.DropdownMenu.show {
  display: block;
}

.DropdownItem {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.LoadingMessage {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.TagsContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.Tags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  width: calc(100% - 40px);
}

.TagsContainer::-webkit-scrollbar {
  display: none;
}

.ArrowIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
}

.ColumnHeader {
  font-weight: 600;
  font-size: 16px;
}

.checkmark-button {
  padding: 10px 20px;
  margin-inline-end: 5px;
  border-radius: 35px;
  margin-bottom: 5px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 13px;
  border: none;
  flex: 0 1 calc(50% - 10px);
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px;
}

.checkmark-button:hover {
  opacity: 0.87;
}

.ArrowIcon.left {
  inset-inline-start: 0;
}

.ArrowIcon.right {
  inset-inline-end: 0;
}

.CheckmarkButton .Tags {
  margin: 0 5px;
}

.TagButton {
  border: none;
  border-radius: 35px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  font-size: 10px;
  transition: ease-in background-color 0.2s;
}

.FixedFooter {
  position: sticky;
  bottom: 0;
  inset-inline-start: 0;
  width: 100%;
  padding: 10px;
  z-index: 1000;
}

.RegularColumn {
  width: 320px;
  border-inline-end: 1px solid #ccc;
  padding: 0px 20px;
}

.AddAllButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  width: 100%;
  background-color: transparent;
}

.AddAllButton .material-icons {
  margin-inline-start: 4px;
  /* Space between text and icon */
}

.ColumnElements {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.afj-columnHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #1e2836;
  color: white;
}

.popupheader {
  margin: 0; /* Ensure no extra margin around the header */
}

@media (max-width: 1200px) {
  .ColumnElements {
    grid-template-columns: 1fr;
    grid-template-areas:
      "firstColumn"
      "secondColumn"
      "thirdColumn";
  }

  .ColumnElements > *:nth-child(1) {
    grid-area: firstColumn;
  }

  .ColumnElements > *:nth-child(2) {
    grid-area: secondColumn;
  }

  .ColumnElements > *:nth-child(3) {
    grid-area: thirdColumn;
  }

  .QueryView {
    width: 685px;
    border-bottom: 1px solid #ccc;
    border-inline-end: none;
  }

  .QueryAlign {
    display: flex;
    justify-content: start;
    gap: 60px;
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .afj-notification-container {
    margin: 20px 0px;
    @media (max-width: 992px) {
      margin-top: 0;
    }
  }

  .afj-edit-column-notification {
    display: block;
    margin: 16px 16px;
    font-weight: 600;
    font-size: 16px;

    @media (max-width: 992px) {
      margin: 0;
    }
  }

  .ChannelsContainer {
    max-height: 50vh;
  }

  .ColumnNameInput {
    height: 40px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .ColumnElements {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "firstColumn firstColumn"
      "secondColumn thirdColumn";
  }

  .ColumnElements > *:nth-child(1) {
    grid-area: firstColumn;
  }

  .ColumnElements > *:nth-child(2) {
    grid-area: secondColumn;
  }

  .ColumnElements > *:nth-child(3) {
    grid-area: thirdColumn;
  }
}

@media (max-width: 769px) {
  .ColumnElements {
    grid-template-columns: 1fr;
    grid-template-areas:
      "firstColumn"
      "secondColumn"
      "thirdColumn";
  }

  .QueryView {
    width: 320px;
    border-bottom: 1px solid #ccc;
  }

  .ColumnContainer {
    max-height: 80vh;
    overflow-y: auto;
  }

  .ColumnElements {
    max-height: 70vh;
    overflow-y: auto;
  }

  .ChannelsContainer {
    height: 300px;
    max-height: 50vh;
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editable-input {
  width: 100%;
  padding: 10px;
  padding-inline-end: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.dark-theme .editable-input {
  background-color: #383838;
  color: white;
}

.close-button {
  position: absolute;
  top: 50%;
  inset-inline-end: 10px;
  padding-inline-end: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.check-button {
  position: absolute;
  top: 50%;
  inset-inline-end: 40px;
  padding-inline-end: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.edit-button {
  margin-inline-start: 10px;
  cursor: pointer;
}

.number-control {
  width: 207px !important;
  height: 40px !important;
}
.dark-theme .number-control {
  background-color: #383838 !important;
  color: white !important;
}

.phone-input-2 .react-international-phone-country-selector-button {
  height: 40px;
}
.phone-input-2 .react-international-phone-country-selector-dropdown {
  z-index: 9999;
  background-color: #fff;
  width: 252px;
}

.dark-theme
  .phone-input-2
  .react-international-phone-country-selector-dropdown {
  background-color: #383838 !important;
  color: white !important;
}

.dark-theme .phone-input-2 .react-international-phone-country-selector-button {
  background-color: #383838 !important;
}
.dark-theme
  .phone-input-2
  .react-international-phone-country-selector-dropdown {
  background-color: #383838 !important;
}

.dark-theme
  .phone-input-2
  .react-international-phone-country-selector-dropdown::-webkit-scrollbar {
  width: 12px !important;
}

.dark-theme
  .phone-input-2
  .react-international-phone-country-selector-dropdown::-webkit-scrollbar-track {
  background: rgb(107, 107, 107) !important;
}

.dark-theme
  .phone-input-2
  .react-international-phone-country-selector-dropdown::-webkit-scrollbar-thumb {
  background-color: #383838 !important;
  border-radius: 10px !important;
  border: 1px solid #ccc !important;
}
.dark-theme
  .phone-input-2
  .react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: #555 !important;
  color: white !;
}

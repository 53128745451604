body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tox-tinymce {
  border-radius: 0 !important;
  padding-top: 0 !important;
  border: 0 !important;
}

[data-sonner-toast] {
  padding: 15px;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
  color: black;
  border-radius: 2px;
  padding: 0 2px;
}

.custom-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  background-color: #e2e2e2;
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #10b981;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.afj-container {
  padding: 0px 20px;
  background-color: #f5f5f5; /* Light gray color for less contrast */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dark-theme .afj-container {
  background-color: #1a1a1a;
  color: white;
}

.afj-auth-buttons {
  position: absolute;
  top: 10px;
  inset-inline-end: 20px;
  display: flex;
  gap: 20px;
}

.afj-login {
  color: gray;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 20px;
  transition: color 0.3s ease;
  margin-top: 10px;
}

.dark-theme .afj-login {
  color: white;
  &:hover {
    color: gray;
  }
}

.afj-login:hover {
  color: black;
}

.afj-get-started {
  color: white;
  background-color: #4f46e5;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.afj-get-started:hover {
  background-color: #3730a3;
}

.afj-main {
  height: 100%;
}

.afj-intro {
  text-align: center;
}

.afj-highlight {
  font-weight: bold;
  color: #4f46e5;
}

.afj-plan-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.afj-toggle-button {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ddd;
  border-radius: 15px;
  cursor: pointer;
}

.afj-toggle-track {
  width: 100%;
  height: 100%;
  background-color: #4f46e5;
  border-radius: 15px;
}

.afj-toggle-thumb {
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  inset-inline-start: 2px;
  transition: transform 0.3s ease;
}

.afj-toggle-thumb-active {
  transform: translateX(30px);
}

.afj-plans {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  height: 400px;
}

.afj-plan {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /* Box shadow */
}

.dark-theme .afj-plan {
  background-color: #1a1a1a;
  color: white;
  border-color: #4f46e5;
}

.afj-price {
  margin-bottom: 20px;
}

.afj-price-value {
  font-size: 24px;
  font-weight: bold;
}

.afj-green {
  color: green;
}

.afj-plan-details {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.afj-plan-features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.afj-feature {
  display: flex;
  align-items: center;
  gap: 10px;
}

.afj-check-icon {
  width: 20px; /* Fixed width */
  height: 20px; /* Fixed height */
  color: green;
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

.afj-cta-button {
  width: 40%;
  padding: 10px 20px;
  border: 1px solid #4f46e5;
  border-radius: 20px;
  cursor: pointer;
  background: none;
  color: #4f46e5;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.afj-cta-button-primary {
  background-color: #4f46e5;
  color: white;
}

.afj-cta-button-primary:hover {
  background-color: #3730a3;
}

.afj-cta-button-secondary:hover {
  background-color: #4f46e5;
  color: white;
}

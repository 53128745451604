
.material-symbols-outlined {
    font-size: 30px; 
 
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' -0,
  'opsz' 30
}

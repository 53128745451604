.content-area-charts {
  display: grid;
  grid-template-columns: 100%;
}

// bar chart
.bar-chart {
  background-color: var(--secondary-color);
  padding: 16px 16px 6px 16px;
  border-radius: 5px;
  box-shadow: var(--light-shadow1);
  position: relative;

  @media (max-width: 768px) {
    padding: 12px;
  }

  .bar-chart-wrapper {
    width: 100%;
    height: 500px;

    // recharts customization
    .recharts-wrapper {
      @media (max-width: 1400px) {
        margin-inline-start: -10px;
      }
    }
  }

  .bar-chart-info {
    margin-bottom: 32px;
  }

  .bar-chart-title {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color-inverted);
  }

  .chart-info-data {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .year-selector {
    position: absolute;
    top: 20px;
    inset-inline-end: 20px;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .year-selector label {
    margin-inline-end: 10px;
    font-size: 14px;
    color: #676767;
  }

  .year-selector select {
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:hover {
      border-color: #aaa;
    }

    &:focus {
      border-color: #475be8;
      box-shadow: 0 0 5px rgba(71, 91, 232, 0.5);
      outline: none;
    }
  }

  .info-data-value {
    color: var(--xl-text-color);
    font-size: 24px;
    font-weight: 700;
  }

  .info-data-text {
    display: flex;
    align-items: center;
    column-gap: 3px;
    color: var(--green-color);
  }

  // customization of recharts - bar chart
  .recharts-default-legend {
    position: absolute;
    top: -90px;
    inset-inline-end: 0;
  }
  .recharts-tooltip-label {
    color: var(--text-color-inverted);
    font-size: 14px;
    font-weight: 600;
  }
}

// progress chart
.progress-bar {
  padding: 16px;
  border-radius: 5px;
  box-shadow: var(--light-shadow1);
  background: var(--secondary-color);

  @media (max-width: 768px) {
    padding: 12px;
  }

  .main-category-title {
    font-size: 20px;
    font-weight: 700;
    color: var(--text-color-inverted);
    margin-bottom: 20px;
  }

  .progress-bar-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--text-color-inverted);
    margin-bottom: 50px;
  }
  .bar-item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 6px;
    margin-bottom: 8px;

    .bar-item-info-name,
    .bar-item-info-value {
      color: var(--text-color-inverted);
      font-weight: 600;
    }
  }
  .progress-bar-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two columns */
    gap: 50px; /* Spacing between items */

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* One column on small screens */
    }
  }

  .bar-item-full {
    width: 100%;
    height: 10px;
    border-radius: 100vh;
    background-color: var(--chart-secondary-color);
    position: relative;
  }

  .bar-item-filled {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    height: 100%;
    width: 0;
    border-radius: 100vh;
    background-color: var(--chart-primary-color);
  }
}
